import { contact } from '../../portfolio'
import './Contact.css'

const Contact = () => {
  if (!contact.email) return null
  return (
    <section className='section contact center' id='contact'>
      <h2 className='section__title'>Kontakt</h2>
      <a href={`mailto:${contact.email}`}>
        {/*@ts-ignore*/}
        <span type='button' className='btn btn--outline'>
          Mail mir
        </span>
      </a>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className="contact-copy" onClick={() => {
          navigator.clipboard.writeText(contact.email);
      }}>{contact.email}</div>
    </section>
  )
}

export default Contact
