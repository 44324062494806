const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: '/',
  title: 'BDS',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Arne Bütergerds',
  role: 'Fullstack Developer',
  description:
    '',
  // resume: 'https://example.com',
  social: {
    linkedin: 'https://www.linkedin.com/in/arne-b%C3%BCtergerds-6557bb1a1',
    github: null,
    instagram: 'https://www.instagram.com/arne_buetergerds/',
    xing: 'https://www.xing.com/profile/Arne_Buetergerds'
  },
  resume: null
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'chayns deliver dashboard',
    description:
      'Alle chayns Bestellungen im Überblick mit direktem Weg zu Google Maps und mit SMS Benachrichtigungen.',
    stack: ['Node.js', 'JavaScript', 'React'],
    creator: {
      url: 'https://qonect.de/',
      name: 'QONCET',
    },
  },
  {
    name: 'chayns & Lightspeed',
    description:
      'Die Platform chayns mit dem Kassensystem Lightspeed verbinden? Kein Problem.',
    stack: ['SASS', 'TypeScript', 'React'],
    creator: {
      url: 'https://qonect.de/',
      name: 'QONCET',
    },
  },
  {
    name: 'chayns car wash',
    description:
        'Auto Wäschen mit chayns Bestellungen kaufen und einlösen.',
    stack: ['Node.js', 'TypeScript', 'React'],
    creator: {
      url: 'https://qonect.de/',
      name: 'QONCET',
    },
  },
  {
    name: 'Individuelles',
    description:
      'Du hast eine Idee und brauchst einen erfahrenden Entwickler? Gerne erschaffe ich Dir dein Programm.',
    stack: ['Alles'],
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'HTML',
  'CSS',
  'JavaScript',
  'TypeScript',
  'Node.js',
  'nest.js',
  'next.js',
  'React',
  'Redux',
  'Material UI',
  'Git',
  'CI/CD',
  'Docker',
  'C#',
  '.net core'
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'arne@buetergerdsds.de',
}

export { header, about, projects, skills, contact }
